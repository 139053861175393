import { Button, tailwindPreset } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { VolleyballMatchControl } from 'src/types/matchControl';
import { VolleyballContestant } from 'src/types/matchControl/contestant';
import { Control } from 'src/types/matchControl/control';
import { VolleyballScore } from 'src/types/matchControl/score';

import { MatchControlButton, MatchControlIcon } from '../components';
import Points from '../components/Points';

const SCORE_BUG_ID = 'scorebug';

type VolleyballProps = {
  matchControl: VolleyballMatchControl;
  contestant1: VolleyballContestant;
  contestant2: VolleyballContestant;
  handleUpdateMatchControl: (matchControl: VolleyballMatchControl) => void;
  handleDispatchControl: (control: Control | undefined) => void;
  matchControlGraphics: Control[];
};

const VolleyballScorecard = ({
  scoreData,
  contestant1,
  contestant2,
}: {
  scoreData: VolleyballScore[];
  contestant1: VolleyballContestant;
  contestant2: VolleyballContestant;
}) => {
  const cellStyle = {
    textAlign: 'center',
    borderLeft: '1px solid #4D4D5B',
    padding: '8px',
    width: '40px',
  } as const;

  const nameCellStyle = {
    textAlign: 'left',
    paddingLeft: '16px',
    width: '100%',
    overflow: 'hidden',
  } as const;

  const currentGameCellStyle = {
    ...cellStyle,
    color: tailwindPreset.theme.extend.colors.primary,
  };

  return (
    <table className="w-full border-collapse bg-[#151621]">
      <tbody>
        <tr className="border-b border-[#4D4D5B]">
          <td style={nameCellStyle}>{contestant1.name}</td>
          {scoreData.map((set, index) => {
            const isLatest = scoreData.length - 1 === index;
            return (
              <td key={index} style={isLatest ? currentGameCellStyle : cellStyle}>
                {set.games.contestant1}
              </td>
            );
          })}
        </tr>
        <tr>
          <td style={nameCellStyle}>{contestant2.name}</td>
          {scoreData.map((set, index) => {
            const isLatest = scoreData.length - 1 === index;
            return (
              <td key={index} style={isLatest ? currentGameCellStyle : cellStyle}>
                {set.games.contestant2}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

const toggleWinnerSet = (set: VolleyballScore, contestant: 'contestant1' | 'contestant2') => {
  const winner = set.winner === contestant ? undefined : contestant;
  return { ...set, winner };
};

const CurrentVolleyballSet = ({
  set,
  contestant1,
  contestant2,
  handleChangeSet,
}: {
  set: VolleyballScore;
  contestant1: VolleyballContestant;
  contestant2: VolleyballContestant;
  handleChangeSet: (set: VolleyballScore) => void;
}) => {
  const { name: nameContestant1 } = contestant1;
  const { name: nameContestant2 } = contestant2;

  const { contestant1: gamesContestant1, contestant2: gamesContestant2 } = set.games;

  return (
    <div className="flex gap-2 flex-col">
      <div className="flex items-center gap-2 [&>div]:flex [&>div]:gap-2 [&>div]:flex-col px-4">
        <div className="flex-auto min-w-0 [&>div]:overflow-hidden [&>div]:text-ellipsis [&>div]:line-clamp-1">
          <div>{nameContestant1}</div>
          <div>{nameContestant2}</div>
        </div>
        <div>
          <Points
            points={gamesContestant1}
            handleAddPoint={() =>
              handleChangeSet({
                ...set,
                games: { ...set.games, contestant1: set.games.contestant1 + 1 },
              })
            }
            handleRemovePoint={() =>
              handleChangeSet({
                ...set,
                games: { ...set.games, contestant1: Math.max(set.games.contestant1 - 1, 0) },
              })
            }
          />
          <Points
            points={gamesContestant2}
            handleAddPoint={() =>
              handleChangeSet({
                ...set,
                games: { ...set.games, contestant2: set.games.contestant2 + 1 },
              })
            }
            handleRemovePoint={() =>
              handleChangeSet({
                ...set,
                games: { ...set.games, contestant2: Math.max(set.games.contestant2 - 1, 0) },
              })
            }
          />
        </div>
      </div>
      <div className="px-4 flex items-center justify-between align-middle">
        <div>Set winner:</div>
        <div className="flex gap-2">
          <Button
            variant={set.winner === 'contestant1' ? 'primary' : 'secondary'}
            buttonSize="small"
            onClick={() => handleChangeSet(toggleWinnerSet(set, 'contestant1'))}
          >
            {nameContestant1}
          </Button>
          <Button
            variant={set.winner === 'contestant2' ? 'primary' : 'secondary'}
            buttonSize="small"
            onClick={() => handleChangeSet(toggleWinnerSet(set, 'contestant2'))}
          >
            {nameContestant2}
          </Button>
        </div>
      </div>
    </div>
  );
};

type VolleyballSetsProps = {
  selectedSetId?: string;
  handleChangeSelectedSetId: (setId: string) => void;
  sets: VolleyballScore[];
  contestant1: VolleyballContestant;
  contestant2: VolleyballContestant;
  handleChangeSets: (sets: VolleyballScore[]) => void;
};

const VolleyballSets = ({
  selectedSetId,
  handleChangeSelectedSetId,
  sets,
  handleChangeSets,
  contestant1,
  contestant2,
}: VolleyballSetsProps) => {
  const currentSet = useMemo(
    () => sets.find((set) => set.id === selectedSetId),
    [sets, selectedSetId]
  );

  const handleAddSet = () => {
    const newSets: VolleyballScore[] = [
      ...sets,
      {
        id: uuidv4(),
        games: { contestant1: 0, contestant2: 0 },
      },
    ];
    handleChangeSets(newSets);
    handleChangeSelectedSetId(newSets[newSets.length - 1].id);
  };

  const handleDeleteSet = (id?: string) => {
    if (!id || !sets.length) return;
    const newSets: VolleyballScore[] = sets.filter((set) => set.id !== id);
    handleChangeSets(newSets);
    const newSelectedSetId = newSets.length ? newSets[newSets.length - 1].id : undefined;
    handleChangeSelectedSetId(newSelectedSetId || '');
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between gap-2 px-4 border-b border-[#151621] min-h-[43px]">
        <div className="flex items-center gap-2">
          {sets.map((set, index) => (
            <Button
              key={index}
              className={classNames({
                '!text-[#777784] !border-transparent': selectedSetId !== set.id,
                '!border-primary': selectedSetId === set.id,
                '!bg-[#232432] !px-0 !border-b-2 !border-t-0 !border-l-0 !border-r-0 focus:!ring-0 !rounded-none':
                  true,
              })}
              onClick={() => handleChangeSelectedSetId(set.id)}
            >
              Set {index + 1}
            </Button>
          ))}
          <MatchControlButton variant="icon" onClick={handleAddSet}>
            <MatchControlIcon iconProps={{ icon: 'plus' }} />
          </MatchControlButton>
        </div>
        <div className="flex items-center gap-2">
          <MatchControlButton variant="icon" onClick={() => handleDeleteSet(selectedSetId)}>
            <MatchControlIcon iconProps={{ icon: 'trash' }} />
          </MatchControlButton>
        </div>
      </div>
      <div className="py-2">
        {currentSet && (
          <CurrentVolleyballSet
            handleChangeSet={(set) =>
              handleChangeSets(sets.map((s) => (s.id === set.id ? set : s)))
            }
            set={currentSet}
            contestant1={contestant1}
            contestant2={contestant2}
          />
        )}
      </div>
    </div>
  );
};

const Volleyball = ({
  matchControl,
  handleUpdateMatchControl,
  handleDispatchControl,
  matchControlGraphics,
  contestant1,
  contestant2,
}: VolleyballProps) => {
  const [selectedSetId, setSelectedSetId] = useState<string>('');
  const sets = matchControl.score;

  const handleChangeSets = (sets: VolleyballScore[]) =>
    handleUpdateMatchControl({ ...matchControl, score: sets });

  const handleChangeServer = (server: 'contestant1' | 'contestant2') =>
    handleUpdateMatchControl({ ...matchControl, server });

  return (
    <div className="flex flex-col gap-2 py-4 text-white">
      {/* Current server / Scorecard */}
      <div className="flex flex-row gap-4 items-stretch px-4">
        <div className="flex flex-col gap-2 justify-evenly">
          <MatchControlButton
            variant="icon"
            className={classNames({
              '!bg-primary': matchControl.server === 'contestant1',
            })}
            onClick={() => handleChangeServer('contestant1')}
          >
            <MatchControlIcon iconProps={{ icon: 'volleyball', weight: 'fill' }} />
          </MatchControlButton>
          <MatchControlButton
            variant="icon"
            className={classNames({
              '!bg-primary': matchControl.server === 'contestant2',
            })}
            onClick={() => handleChangeServer('contestant2')}
          >
            <MatchControlIcon iconProps={{ icon: 'volleyball', weight: 'fill' }} />
          </MatchControlButton>
        </div>
        <div>
          <VolleyballScorecard
            scoreData={sets}
            contestant1={contestant1}
            contestant2={contestant2}
          />
        </div>
      </div>
      {/* Sets panel */}
      <div>
        <VolleyballSets
          selectedSetId={selectedSetId}
          handleChangeSelectedSetId={(setId) => setSelectedSetId(setId)}
          sets={sets}
          contestant1={contestant1}
          contestant2={contestant2}
          handleChangeSets={handleChangeSets}
        />
      </div>
      {/* Score bug in */}
      <div className="flex justify-between [&>button]:h-7 px-4">
        <MatchControlButton
          onClick={() =>
            handleDispatchControl(matchControlGraphics?.find((c) => c.controlId === SCORE_BUG_ID))
          }
          isRunning={Boolean(
            matchControlGraphics?.find(
              (control) => control.controlId === SCORE_BUG_ID && control.live
            )
          )}
        >
          SCORE BUG IN
        </MatchControlButton>
      </div>
    </div>
  );
};

export default Volleyball;
